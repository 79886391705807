@import 'src/shared/styles/colors.scss';

.spantech__confirm-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.801);
  display: flex;
  align-items: center;
  justify-content: center;
  &__container {
    background: $white;
    position: relative;
    border-radius: 4px;
    min-width: 580px;
    max-width: 650px;
    &__close {
      position: absolute;
      right: 10px;
      top: 10px;
    }
    &__buttons {
      display: flex;
      justify-content: flex-end;
      padding: 0 24px 16px 24px;
      .tm-button {
        margin-left: 10px;
      }
    }
  }
  &__header-text {
    @include font(h3);
    color: $novaBlue;
    padding: 16px 24px;
    border-bottom: 1px solid $cloud;
    display: flex;
    .icon-component {
      margin-right: 24px;
    }
  }
  &__body {
    padding: 16px 24px;

    h3 {
      @include font(b-bold);
      max-width: 75%;
    }
    .text--red {
      color: $negative;
    }
    .subtext--red {
      color: $negative;
      font-size:80%
    }
    .text--navy {
      color: $navy;
    }
    ul {
      @include font(h5);
      padding-inline-start:20px;
      line-height:20px;
      max-height:60vh;
      overflow:auto;
      li {
        margin-bottom: 6px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-text {
      @include font(b);
      padding: 16px 24px;
      margin: 0;
      &--ul {
        @include font(h5);
        padding: 16px 24px;
        margin: 0 0 0 24px;
        li {
          margin-bottom: 6px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
